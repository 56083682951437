/* HEADER BOX STYLING */
.ProjectHeader {
    margin: 1% 17.5% 0% 17.5%;
    padding-bottom: 1%;
    background-color: rgb(216, 216, 216);
    border-radius: 25px;
    height: 80px;
    width: 65%;
    cursor: pointer;
}

.TitleType {
    float: left;
}

.ProjectBoxTitle {
    margin: 8px 0% 0% 1%;
    padding: 0% 0% 0% 0%;
    font-size: x-large;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    white-space: nowrap;
}

.ProjectBoxType {
    margin: 0% 0% 0% 1%;
    padding: 0% 0% 0% 0%;
}

.ProjectHeaderText {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin: 0% 0% 0% 2.5%;
    padding: 1% 0% 0% 0%;
}

.TechImagesBox {
    margin-left: 3%;
    height: 40px;
    width: fit-content;
    float: left;
    display: flex;
    object-fit: contain;
}

.TechImg {
    margin: 1% 15px 0% 0%;
    object-fit: contain;
    display: block;
    max-width: fit-content;
    height: 40px;
}

.ProjectDate {
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-right: 3%;
    float: right;
    margin-top: 30px;
}

/* CONTENT BOX STYLING */
.ProjectContentBox {
    margin: 1% 20% 1% 20%;
    background-color: rgb(239, 242, 242);
    border-radius: 25px;
    width: 60%;
    font-family: 'Roboto', sans-serif;
    border: 2px solid black
}

.ProjectContentLink {
    float: left;
    padding: 0% 5% 0% 0%;
    margin: 1% 0% 1% 2%;
    width: 100%
}

.ProjectContentText {
    padding: 0% 5% 1% 5%;
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (max-width: 1000px) {
    .ProjectHeader {
        margin: 1% 7.5% 2% 7.5%;
        padding-bottom: 1%;
        background-color: rgb(216, 216, 216);
        border-radius: 25px;
        height: 90px;
        width: 85%;
        cursor: pointer;
        border: 2px solid black;
        color: black !important;
        display: flex;
    }

    .ProjectHeaderText {
        font-family: 'Roboto', sans-serif;
        text-align: left;
        margin: 0% 0% 0% 4%;
        padding: 1% 0% 0% 0%;
    }

    .ProjectBoxTitle {
        margin: 8px 0% 0% 1%;
        padding: 0% 0% 0% 0%;
        width: 240px;
        height: fit-content;
        overflow: hidden;
        white-space: nowrap;
        font-size: 15px;
    }
  
    .ProjectBoxType {
        margin: 0% 0% 0% 1%;
        padding: 0% 0% 0% 0%;
        font-size: smaller;
        overflow: hidden;
        white-space: nowrap;
    }

    .TechImagesBox {
        margin: 5% 0% 0% 0%;
        float: left;
        display: flex;
        gap: 5px;
        height: fit-content;
        padding: 0%;
    }

    .TechImg {
        height: 20px;
        margin: 0%;
    }

    .ProjectDate {
        float: right;
        font-size: smaller;
        margin: 0% 2% 0% 0%;
        padding: 0%;
    }

    .ProjectContentLink {
        float: left;
        padding: 4% 0% 0% 4%;
        margin: 1% 0% 1% 2%;
        width: 100%
    }

    .ProjectContentBox {
        margin: 2% 10% 5% 10%;
        background-color: rgb(239, 242, 242);
        border-radius: 25px;
        width: 80%;
        font-family: 'Roboto', sans-serif;
        border: 2px solid black;
    }

    .ProjectContentText {
        margin: 1% 8% 2% 8%;
    }
}