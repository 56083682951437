.topnav {
    margin: 0px 12.5% 0px 12.5%;
    overflow: hidden;
}
  
  /* Style the links inside the navigation bar */
.topnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
  
  /* Change the color of links on hover */
.topnav a:hover {
    background-color: #ddd;
    color: black;
}
  
/* Create a right-aligned (split) link inside the navigation bar */
.topnav a.split {
    float: right;
    color: black;
}

hr {
    margin: 0.5% 10% 0px 10%;
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (max-width: 1000px) {
    .topnav {
        margin: 4% 6.75% 2% 6.75%;
        display: flex;
        /* overflow: auto;
        overflow-x: scroll; */
        text-align: center;
        gap: 4%;
    }

    .topnav a {
        float: left;
        padding: 0%;
        font-size: 12px;
        line-height: 14px;
    }

    hr {
        margin: 0.5% 5% 0px 5%;
    }
}