.EssaysSplit {
    margin: 1% 10% 0% 10%;
}

.EssaysRow {
    margin: 2% 0% 2% 0%;
    display: flex;
    justify-content: center;
    gap: 4%;
}

.RowImg {
    margin: 0%;
    padding: 0%;
    max-width: 100%;
    height: auto;
}

.RowLink {
    position: relative;
    text-align: center;
    display: block;
    max-width: 35%;
}

.RowLink:hover .HoverText{
    opacity: .85;
}

.HoverText {
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: larger;
    color: black;
    opacity: 0;
    transition: .5s ease;
    width: 100%;
    height: 100%
}

.HoverInnerText {
    margin: 0%;
    padding: 0%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {
    .EssaysRow {
        margin: 0%;
        padding: 0%;
        display: block;
    }

    .RowImg {
        margin: 0% 0% 2.5% 0%;
        padding: 0%;
        width: 100%;
        height: 200px;
    }

    .RowLink {
        margin: 0% 7.5% 2.5% 7.5%;
        padding: 0%;
        max-width: 85%;
    }

    .HoverText {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: small;
        opacity: 1;
        transition: none;
        padding: 200px 0% 0% 0%;
        margin: 0%;
    }

    .HoverInnerText {
        position: absolute;
        color: white;
        font-size: 12px;
        width: 200px;
        margin: 0% 0% 0% 0%;
        padding: 0%;
    }

    .EssaysSplit {
        margin: 1% 5% 3% 5%;
    }
}