h4 {
    font-family: 'Roboto', sans-serif;
    margin-top: 15px;
    float: right;
    color: black;
}

/* Create a top navigation bar with a black background color  */
.botnav {
    margin: 0px 12.5% 0px 12.5%;
    overflow: hidden;
}
  
  /* Style the links inside the navigation bar */
.botnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
  
  /* Change the color of links on hover */
.botnav a:hover {
    background-color: #ddd;
    color: black;
}
  
/* Create a right-aligned (split) link inside the navigation bar */
.botnav a.split {
    float: right;
    color: black;
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (max-width: 1000px) {
  .botnav {
    display: flex;
    margin: 1% 6.75% 15% 6.75%;
    padding: 0%;
    gap: 2%;
    height: 55px;
  }

  .botnav a {
    float: left;
    padding: 0%;
  }

  .botnav h4 {
    margin-left: 17px;
    margin-right: 0px;
    font-size: 8px;
    text-align: right;
  }
}