h1 {
    padding-top: 3%;
    margin-bottom: 0px;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 42pt;
    text-align: center;
}

h2 {
    margin-top: 0px;
    padding-bottom: 1%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: italic;
    text-align: center;
}


@media screen and (max-width: 1000px) {
    h1 {
        padding-top: 5%;
        margin-bottom: 0px;
        color: black;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
    }

    h2 {
        margin-top: 0px;
        padding-bottom: 1%;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        font-style: italic;
        text-align: center;
    }
}