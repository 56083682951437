/* HEADER BOX STYLING */
.header {
    margin: 1% 17.5% 0% 17.5%;
    padding-bottom: 1%;
    background-color: rgb(216, 216, 216);
    border-radius: 25px;
    height: 80px;
    width: 65%;
    cursor: pointer;
}

.ExperienceBoxTitle {
    margin: 0% 0% 0% 2.5%;
    padding: 1% 0% 0% 0%;
}

.ExperienceBoxCompany {
    padding: 1% 0% 0% 0%;
}

.Text {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin: 0% 0% 0% 2.5%;
    padding: .5% 0% 0% 0%; 
}

.Date {
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-right: 3%;
    float: right;
}

.Arrow {
    float:left;
    padding-top: 27px;
    padding-left: 5px;
}

.ImageBox {
    float:left;
    height: 65px;
    width: 110px;
    padding: 0% 0% 0% 0%;
    margin-top: 5px;
}

.LogoBox {
    padding: 7% 0% 0% 6%;
    max-width: 90%;
    max-height: 90%;
    width: 100%;
    object-fit: contain;
    margin: auto;
    display: block;
    
}

/* CONTENT BOX STYLING */
.ExperienceContentBox {
    margin: 1% 20% 1% 20%;
    background-color: rgb(239, 242, 242);
    border-radius: 25px;
    width: 60%;
    font-family: 'Roboto', sans-serif;
    border: 2px solid black
}

.ExperienceContentHeader {
    float: left;
    padding: 1% 5% 0% 0%;
    margin: 1% 0% 1% 2%;
    width: 100%
}

.ExperienceContentText {
    padding: 0% 5% 1% 5%;
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (max-width: 1000px) {
    .header {
        margin: 1% 7.5% 2% 7.5%;
        padding-bottom: 1%;
        background-color: rgb(216, 216, 216);
        border-radius: 25px;
        height: 90px;
        width: 85%;
        cursor: pointer;
        border: 2px solid black;
        color: black !important;
        display: flex;
    }

    .ExperienceContentHeader {
        float: left;
        padding: 2% 5% 0% 1%;
        margin: 1% 0% 1% 2%;
        width: 100%
    }

    .ExperienceBoxTitle {
        margin: 0% 0% 0% 0%;
        padding: 1% 0% 0% 0%;
        font-size: 13px;
    }
    
    .ExperienceBoxCompany {
        padding: 0%;
        font-size: small;
        float: left;
        margin-top: 1%;
    }

    .Date {
        float: left;
        margin-top: 10%;
        font-size: small;
    }

    .ImageBox {
        margin-top: 2.5%;
        padding: 0%;
        display: flex;
    }
    
    .LogoBox {
        padding: 0%;
        width: fit-content;
        height: max-content;
    }

    .ExperienceContentBox {
        margin: 1% 10% 6% 10%;
        background-color: rgb(239, 242, 242);
        border-radius: 25px;
        width: 80%;
        font-family: 'Roboto', sans-serif;
        border: 2px solid black !important;
    }

    .ExperienceContentHeader {
        float: left;
        padding: 4% 5% 0% 0%;
        margin: 0% 0% 1% 4%;
        width: 100%
    }
    
    .ExperienceContentText {
        padding: 1% 8% 2% 8%;
    }

    .Text {
        font-family: 'Roboto', sans-serif;
        text-align: left;
        margin: 2.5% 0% 2.5% 0%;
        padding: 0% 0% 0% 0%; 
    }
}

