h3 {
    margin: 1% 0px 0px 17.5%;
}


p {
    font-family: 'Roboto', sans-serif;
    font-size: medium;
    margin: 1% 17.5% 0px 17.5%;
}

/****************** TECHNOLOGIES IMAGES *********************/

.row {
    display: flex;
    margin: 10px;
    margin: 0.25% 0% 0% 17.5%;
    overflow: auto;
}

img {
    float: left;
    max-width:  250px;
    height: 100px;
    object-fit: cover;
    margin: 25px;
}

@media screen and (max-width: 1000px) {
    h3 {
        margin: 4% 0px 4% 7.5%;
    }

    p {
        font-size: 15px;
        margin: 1% 10% 0px 7.5%;
    }

    .row {
        display: flex;
        margin: 5% 7.5% 0% 7.5%;
        padding: 0%;
        gap: 2%;
    }
    
    img {
        margin: 0%;
        float: left;
        max-width:  40px;
        height: 40px;
    }
}