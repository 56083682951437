.HeaderImgDiv {
    margin: 3% 35% 1% 35%;
    padding: 0%;
    display: inline-block;
    border-radius: 25px;
}

.HeaderImg {
    margin: 0%;
    padding: 0%;
    border-radius: 25px;
    max-width: 100%;
    height: auto;
}

.InnerImgDiv {
    margin: 0% 22.5% 2% 22.5%;
    padding: 0%;
    display: inline-block;
    border-radius: 25px;
    text-align: center;
}

.InnerImg {
    margin: 0%;
    padding: 0%;
    border-radius: 25px;
    max-width: 100%;
    height: auto;
}

.InnerImgCaption {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: small;
}

.EssayTitle {
    margin: 1% 25% 1% 25%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 700;
    color: black;
    font-style: normal;
    text-align: center;
}

.FilmTitle { 
    margin-top: 0px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 700;
    color: black;
    font-style: italic;
    text-align: center;
}

.EssayContent {
    margin: 0% 22.5% 1% 22.5%;
    display: inline-block;
}

.EssayContent::first-letter{
    font-size: 2;
}

.EssayContent p {
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 0%;
    line-height: 2;
}

.first-letter {
    float: left;
    font-family: Georgia, serif;
    font-size: 70px;
    line-height: 55px;
    padding: 3px 7px 0px 3px;
}

.Signature {
    margin-top: 5%;
}

.Splitter {
    margin: 0% 17.5% 0% 17.5%;
}

.Bibliography {
    margin: 1% 0% 0% 0%;
}

.BibliographyButton {
    margin: 1% 5% 0% 5%;
    padding: 5px;
    width: fit-content;
    cursor: pointer;
}

.BibliographyText {
    margin: 0% 0% 2% 0%;
}

.BibliographyText p{
    margin: 1% 25% 0% 25%;
}

.Download {
    margin: 0% 22.5% 2% 22.5%;
    width:fit-content;
}

.DownloadButton {
    width:fit-content;
}

.DownloadText {
    color: black;
    width:fit-content;
    margin: 0% 0% 0% 0%;
    padding: 5px;
}

.DownloadButton:link { text-decoration: none; }


.DownloadButton:visited { text-decoration: none; }


.DownloadButton:hover { text-decoration: none; }


.DownloadButton:active { text-decoration: none; }

@media screen and (max-width: 1000px) {
    .EssayTitle {
        margin: 1% 10% 1% 10%;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: small;
        color: black;
        font-style: normal;
        text-align: center;
    }
    
    .FilmTitle { 
        margin-top: 0px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: small;
        color: black;
        font-style: italic;
        text-align: center;
    }

    .EssayContent {
        margin: 0% 5% 1% 5% !important;
        display: inline-block;
    }

    .EssayContent p {
        color: black;
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin: 0%;
        line-height: 2;
        font-size: smaller;
    }

    .Splitter {
        margin: 0% 10% 0% 10% !important;
    }

    .first-letter {
        float: left;
        font-family: Georgia, serif;
        font-size: 46px !important;
        line-height: 46px;
        padding: 0px 7px 0px 3px;
    }

    .HeaderImgDiv {
        margin: 3% 17.5% 1% 17.5% !important;
        padding: 0%;
        display: inline-block;
        border-radius: 25px;
        
    }
    
    .HeaderImg {
        border-radius: 25px;
        max-width: 100%;
        height: auto;
    }

    .InnerImgDiv {
        margin: 3% 0% 1% 0% !important;
        width: 100%;
        padding: 0%;
        display: inline-block;
        border-radius: 25px;
        text-align: center;
    }
    
    .InnerImg {
        margin: 0%;
        padding: 0%;
        border-radius: 25px;
        max-width: 100%;
        height: auto;
    }

    .InnerImgCaption {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 10px;
    }
}


