.EmailForm {
    text-align: center;
    max-width: 350px;
    margin: 40px auto;
}

Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
}