object {
    width: 900px;
    height: 1200px;
    margin: 2% 0% 0% 17.5%;
}

.PDFError {
    background-color: rgb(230, 230, 230);
    width: fit-content;
    padding: 2% 2% 2% 2%;
    border-radius: 25px;
}

@media screen and (max-width: 1000px) {
    object {
        width: 90%;
        height: auto;
        margin: 5% 5% 0% 5%;
    }
}