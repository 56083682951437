.ExperienceSplit {
    margin: 1% 15% 0% 15%;
}

@media screen and (max-width: 1000px) {
    .ExperienceSplit {
        margin: 1% 5% 0% 5%;
    }

    
}